import Vue from 'vue'
import VueRouter from 'vue-router'
import cookies from 'vue-cookies'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'login',
    meta: {
      title: '登录',
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/login.vue'),
  },
  {
    path: '/check-health',
    name: 'check-health',
    meta: {
      title: '健康检查',
    },
    component: () =>
      import(
        /* webpackChunkName: "check-health" */ '../views/check-health.vue'
      ),
  },
  {
    path: '/home',
    meta: {
      title: '主页面',
    },
    component: () => import(/* webpackChunkName: "home" */ '../views/home.vue'),
    children: [
      {
        path: '/index',
        name: '首页',
        component: () =>
          import(/* webpackChunkName: "index" */ '../views/index/index.vue'),
      },
      {
        path: '/platManage',
        name: '平台管理',
        component: () =>
          import(
            /* webpackChunkName: "platManage" */ '../views/platManage/platManage.vue'
          ),
      },
      {
        path: '/openAccountManage',
        name: '开户管理',
        component: () =>
          import(
            /* webpackChunkName: "openAccountManage" */ '../views/openAccountManage/openAccountManage.vue'
          ),
      },
      {
        path: '/accountManage',
        name: '账户管理',
        component: () =>
          import(
            /* webpackChunkName: "accountManage" */ '../views/accountManage/accountManage.vue'
          ),
      },
      {
        path: '/transactionManage',
        name: '交易管理',
        component: () =>
          import(
            /* webpackChunkName: "transactionManage" */ '../views/transactionManage/transactionManage.vue'
          ),
      },
      {
        path: '/listStatementDetail',
        name: '账户明细查询',
        component: () =>
          import(
            /* webpackChunkName: "transactionManage" */ '../views/listStatementDetail/listStatementDetail.vue'
          ),
      },
      {
        path: '/system',
        name: '系统设置',
        component: () =>
          import(/* webpackChunkName: "system" */ '../views/system/system.vue'),
        children: [
          {
            path: '/bank',
            name: '支持银行管理',
            component: () =>
              import(
                /* webpackChunkName: "employeeManage" */ '../views/system/bank/main.vue'
              ),
          },
          {
            path: '/employeeManage',
            name: '员工管理',
            component: () =>
              import(
                /* webpackChunkName: "employeeManage" */ '../views/system/employeeManage/employeeManage.vue'
              ),
          },
          {
            path: '/permissionsManage',
            name: '权限管理',
            component: () =>
              import(
                /* webpackChunkName: "permissionsManage" */ '../views/system/permissionsManage/permissionsManage.vue'
              ),
          },
          {
            path: '/opLog',
            name: '操作日志',
            component: () =>
              import(
                /* webpackChunkName: "opLog" */ '../views/system/opLog/opLog.vue'
              ),
          },
        ],
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  const token = cookies.get('ceres_manage_token')
  const isLogin = token && token !== '""'
  if (to.path === '/') {
    isLogin ? next('/index') : next()
  } else {
    // next()
    isLogin ? next() : next('/')
  }
})

export default router
