import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        breadList: [], // 面包屑导航
        tokenInfo: {} // token
    },
    mutations: {
        setBreadList(state, data) {
            state.breadList = data
        },
        setTokenInfo(state, data) {
            state.tokenInfo = data
        }
    },
    actions: {
    },
    modules: {
    }
})
