import Vue from 'vue'

const hasPermission = requirePermission => {
    let requirePermissionList = Array.isArray(requirePermission) ? requirePermission : [requirePermission]
    let allPermissionList = JSON.parse(localStorage.getItem('permissionsList'))
    let userPermissionList = JSON.parse(localStorage.getItem('userPermissionsList'))
    let newArr = []
    let pathArr = []
    newArr = allPermissionList ? allPermissionList.filter(item => userPermissionList.indexOf(item.rtId) > -1) : []
    newArr.forEach(item => {
        pathArr.push(...item.path)
    });
    return requirePermission ? pathArr.some(e => requirePermissionList.includes(e)) : true
}

Vue.directive('perm', {
    inserted: ((el, binding) => {
      if (!hasPermission(binding.value)) {
        el.parentNode.removeChild(el)
      }
    })
  })