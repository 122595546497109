import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import AFTableColumn from 'af-table-column'
import VueCookies from 'vue-cookies'
import VueClipboard from 'vue-clipboard2'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import router from './router'
import store from './store'
import './assets/css/base.styl'
import './assets/js/permission'

Vue.use(ElementUI)
Vue.use(AFTableColumn)
Vue.use(VueCookies)
Vue.use(VueClipboard)

Vue.prototype.$axios = axios
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
